/* global google */
import React, { useState, useCallback, useEffect, useRef, useMemo, useLayoutEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker, MarkerF } from '@react-google-maps/api';
import type { Store } from './types';
import stringSimilarity from 'string-similarity';
import './App.css';
import placesData from './places.json';
import { Libraries } from '@react-google-maps/api';

const useStyles = () => ({
  mapContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    margin: '20px',
  },
  mapSection: {
    width: '100%',
    marginBottom: '20px',
  },
  '@media (orientation: landscape)': {
    mapContainer: {
      flexDirection: 'row' as const,
      justifyContent: 'space-between',
    },
    mapSection: {
      width: 'calc(50% - 10px)',
      margin: '0 10px',
    },
  },
});

// Extend existing types using module augmentation
const libraries: Libraries = ['places', 'geocoding'];

// Add CSS for the app background
const appStyle: React.CSSProperties = {
  backgroundColor: '#3D4553',
  minHeight: '100vh',
  padding: '20px',
};

// Add CSS for common button style
const commonButtonStyle: React.CSSProperties = {
  padding: '10px 20px',
  backgroundColor: '#4C5763',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontFamily: "'Tiny5', sans-serif",
  fontSize: '16px',
};

// Add CSS for the How to Play button
const howToPlayButtonStyle: React.CSSProperties = {
  ...commonButtonStyle,
  position: 'absolute',
  top: '20px',
  right: '20px',
};

// Add CSS for the instructions modal
const instructionsModalStyle: React.CSSProperties = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '10px',
  width: '70vw',
  height: '70vh',
  overflow: 'auto',
  zIndex: 1000,
};

const modalOverlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
};

interface LatLngLiteral {
  lat: number;
  lng: number;
}

// Add this new interface
interface ExtendedTextSearchRequest extends google.maps.places.PlaceSearchRequest {
  keyword?: string;
  type?: string;
}


const getCityName = (location: LatLngLiteral): Promise<string> => {
  return new Promise((resolve) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location }, (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
      if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
        for (let component of results[0].address_components) {
          if (component.types.includes('locality')) {
            resolve(component.long_name);
            return;
          }
        }
      }
      resolve('Unknown');
    });
  });
};


const MapComponent: React.FC = () => {
  const styles = useStyles();
  const [pointAStores, setPointAStores] = useState<Store[]>([]);
  // const [pointBStores, setPointBStores] = useState<Store[]>([]);
  const [selectedStores, setSelectedStores] = useState<Set<string>>(new Set());

  const mapRefA = useRef<google.maps.Map | null>(null);
  // const mapRefB = useRef<google.maps.Map | null>(null);
  const [selectedPath, setSelectedPath] = useState<Store[]>([]);
  const [totalTime, setTotalTime] = useState<number>(0); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [gameCompleted, setGameCompleted] = useState<boolean>(false); // eslint-disable-line @typescript-eslint/no-unused-vars
  // const [pointBClicked, setPointBClicked] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(30 * 60); // 30 minutes in seconds
  const [pointA, setPointA] = useState<LatLngLiteral | null>(null);
  const [pointB, setPointB] = useState<LatLngLiteral | null>(null);
  const [pointsReady, setPointsReady] = useState(false);
  const [pointAName] = useState<string>(placesData.pointA);
  const [, setPointAStore] = useState<Store | null>(null);
  const [, setPointBStore] = useState<Store | null>(null);
  const [lastSelectedPlace, setLastSelectedPlace] = useState<string>(pointAName);
  const [mapABounds, setMapABounds] = useState<google.maps.LatLngBounds | null>(null);
  // const [mapBBounds, setMapBBounds] = useState<google.maps.LatLngBounds | null>(null);
  const [showInstructions, setShowInstructions] = useState<boolean>(false);

  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries,
  });

  const isSameChain = useCallback((store1: Store, store2: Store): boolean => {
    if (!store1.name || !store2.name) return false;
    
    const name1 = store1.name.toLowerCase();
    const name2 = store2.name.toLowerCase();
    
    // Check if one name contains the other
    if (name1.includes(name2) || name2.includes(name1)) {
      return true;
    }
    
    // If not, fall back to string similarity for other cases
    const similarity = stringSimilarity.compareTwoStrings(name1, name2);
    return similarity > 0.7; // You can adjust this threshold as needed
  }, []);

  const handleSearch = useCallback((
    mapBounds: google.maps.LatLngBounds | null,
    setStores: React.Dispatch<React.SetStateAction<Store[]>>,
    query: string
  ) => {
    if (!isLoaded || !window.google || !mapBounds) {
      return;
    }
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    const request: ExtendedTextSearchRequest = {
      bounds: mapBounds,
      type: 'establishment',
      keyword: query
    };

    service.nearbySearch(
      request,
      (
        results: google.maps.places.PlaceResult[] | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
          const storesAndRestaurants = results.map(result => ({
            ...result,
            chain: result.name ? result.name.split(' ')[0] : 'Unknown', // Simple chain detection
            type: result.types?.includes('restaurant') ? 'restaurant' : 'store'
          })) as Store[];
          setStores(storesAndRestaurants);
        }
      }
    );
  }, [isLoaded]);

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (isLoaded && window.google) {
        const geocoder = new window.google.maps.Geocoder();
        
        const getCoordinates = (address: string): Promise<LatLngLiteral> => {
          return new Promise((resolve, reject) => {
            geocoder.geocode({ address }, (results, status) => {
              if (status === 'OK' && results && results[0]) {
                const location = results[0].geometry.location;
                resolve({ lat: location.lat(), lng: location.lng() });
              } else {
                reject(new Error(`Geocoding failed for address: ${address}`));
              }
            });
          });
        };

        try {
          const [pointACoords, pointBCoords] = await Promise.all([
            getCoordinates(placesData.pointA),
            getCoordinates(placesData.pointB)
          ]);

          setPointA(pointACoords);
          setPointB(pointBCoords);

          const [pointACity, pointBCity] = await Promise.all([
            getCityName(pointACoords),
            getCityName(pointBCoords)
          ]);

          const pointAStore: Store = {
            name: placesData.pointA,
            geometry: { location: new google.maps.LatLng(pointACoords.lat, pointACoords.lng) },
            chain: 'PointA',
            city: pointACity
          };

          const pointBStore: Store = {
            name: placesData.pointB,
            geometry: { location: new google.maps.LatLng(pointBCoords.lat, pointBCoords.lng) },
            chain: 'PointB',
            city: pointBCity
          };

          setPointAStore(pointAStore);
          setPointBStore(pointBStore);
          setSelectedPath(prevPath => {
            if (!prevPath.some(store => store.name === pointAStore.name)) {
              return [pointAStore, ...prevPath];
            }
            return prevPath;
          });
        } catch (error) {
          console.error('Error fetching coordinates:', error);
        }
      }
    };

    fetchCoordinates();
  }, [isLoaded]);

  useLayoutEffect(() => {
    if (pointA && pointB) {
      setPointsReady(true);
    }
  }, [pointA, pointB]);

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    console.error('Google Maps API key is missing. Please set the REACT_APP_GOOGLE_MAPS_API_KEY environment variable.');
  }

  const getRunningTime = useCallback((origin: LatLngLiteral, destination: LatLngLiteral): number => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (destination.lat - origin.lat) * Math.PI / 180;
    const dLon = (destination.lng - origin.lng) * Math.PI / 180;
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(origin.lat * Math.PI / 180) * Math.cos(destination.lat * Math.PI / 180) *
              Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c;
    const runningPace = 12; // km/h, adjust as needed
    return (distance / runningPace) * 60; // Convert to minutes
  }, []);

  const addToPath = useCallback((store: Store) => {
    const storeId = store.place_id || store.name || '';
    const storeLocation = {
      lat: store.geometry?.location?.lat() || 0,
      lng: store.geometry?.location?.lng() || 0
    };

    console.log('Adding store:', store.name);

    const addStoreToPath = (city: string) => {
      setSelectedPath(prevPath => {
        console.log('Previous path:', prevPath.map(s => s.name));
        
        // Check if the store is already in the path
        const storeIndex = prevPath.findIndex(s => s.place_id === store.place_id || s.name === store.name);

        if (storeIndex !== -1 && selectedStores.has(storeId)) {
          console.log('Store already in path, removing:', store.name);
          // If the store is already in the path and selected, remove it (user deselected)
          const newPath = prevPath.filter((_, index) => index !== storeIndex);

          // Update selectedStores
          setSelectedStores(prevSelected => {
            const newSelected = new Set(prevSelected);
            newSelected.delete(storeId);
            return newSelected;
          });

          // Update lastSelectedPlace
          setLastSelectedPlace(newPath.length > 0 ? newPath[newPath.length - 1].name || '' : pointAName);

          // Recalculate total time and remaining time
          const newTotalTime = newPath.reduce((total, s) => total + (s.timeFromPrevious || 0), 0);
          setTotalTime(newTotalTime);
          setRemainingTime(Math.max(0, 30 * 60 - newTotalTime * 60));

          setGameCompleted(false);
          console.log('New path after removal:', newPath.map(s => s.name));
          return newPath;
        } else {
          console.log('Adding new store to path:', store.name);
          // If it's a new store, add it to the path
          setLastSelectedPlace(store.name || '');
          let timeFromPrevious = 0;
          if (prevPath.length === 0 && pointA) {
            timeFromPrevious = getRunningTime(pointA, storeLocation);
          } else if (prevPath.length > 0) {
            const lastStore = prevPath[prevPath.length - 1];
            if (!isSameChain(lastStore, store)) {
              const lastLocation = {
                lat: lastStore.geometry?.location?.lat() || 0,
                lng: lastStore.geometry?.location?.lng() || 0
              };
              timeFromPrevious = getRunningTime(lastLocation, storeLocation);
            }
          }

          const newStore = { ...store, city, timeFromPrevious };
          const newPath = [...prevPath, newStore];

          // Update selectedStores
          setSelectedStores(prevSelected => {
            const newSelected = new Set(prevSelected);
            newSelected.add(storeId);
            return newSelected;
          });

          // Update total time and remaining time
          const newTotalTime = newPath.reduce((total, s) => total + (s.timeFromPrevious || 0), 0);
          setTotalTime(newTotalTime);
          setRemainingTime(Math.max(0, 30 * 60 - newTotalTime * 60));

          // Check if the game is completed
          if (store.name === placesData.pointB) {
            console.log('Point B reached, ending game');
            setGameCompleted(true);
          }

          console.log('New path after addition:', newPath.map(s => s.name));
          return newPath;
        }
      });
    };

    if (store.name === placesData.pointB) {
      if (pointB) {
        getCityName(pointB).then(addStoreToPath);
      } else {
        console.error('pointB is null');
      }
    } else {
      getCityName(storeLocation).then(addStoreToPath);
    }
  }, [pointA, pointB, getRunningTime, isSameChain, pointAName, selectedStores, placesData.pointB]);


  const formatTime = useCallback((seconds: number) => {
    const roundedSeconds = Math.round(seconds);
    const minutes = Math.floor(roundedSeconds / 60);
    const remainingSeconds = roundedSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }, []);

  const getTimeColor = useCallback((remainingTime: number) => {
    const maxTime = 30 * 60; // 30 minutes in seconds
    const ratio = remainingTime / maxTime;
    const red = Math.round(255 * (1 - ratio));
    const green = Math.round(255 * ratio);
    return `rgb(${red}, ${green}, 0)`;
  }, []);

  const tMinusDisplay = useMemo(() => formatTime(remainingTime), [remainingTime, formatTime]);
  const timeColor = useMemo(() => getTimeColor(remainingTime), [remainingTime, getTimeColor]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading…</div>;
  }

  return (
    <div className="app-container" style={appStyle}>
      <div className="game-title" style={{ fontSize: '2em', fontWeight: 'bold', color: 'white', fontFamily: "'Tiny5', sans-serif" }}>teleport/RUN</div>
      <button onClick={toggleInstructions} style={howToPlayButtonStyle}>
        How to Play
      </button>
      {showInstructions && (
        <>
          <div style={modalOverlayStyle} onClick={toggleInstructions}></div>
          <div style={instructionsModalStyle}>
            <h2>The Game</h2>
            <p>You are one of the two Teleport Runners tasked with observing Earth's developments without intervening. You have the power to teleport between stores of the same chain, and that's how you travel the world. Recently, the other Teleport Runner has gone rogue and is attempting to alter the course of history. Your mission is to reach and neutralize the rogue runner within 30 minutes, or else the changes will become permanent.</p>
            <h2>Gameplay Mechanics</h2>
            <ol>
              <li>Teleportation and Running: Use the branches of chain stores or restaurants around the globe to teleport closer to the rogue runner. You can also run short distances between nearby locations. The key is to select the best combination of places to run to or teleport to, minimizing travel time.</li>
              <li>Maps and Locations: Use the search function in the map to find stores or restaurants you can run or teleport to. Use the ↗️ buttons to easily switch the view of the map.  Tip: If you don't know what to search for, start by simply searching for "stores" or "restaurants" and look for those that might help you get closer to your goal.</li>
              <li>When you click on the marker of a store you will move there, and the clock will reflect the time spent until now. You can deselect the store if you need to rethink your strategy.</li>
              <li>Click on the yellow marker where the rogue runner is to complete your journey.</li>
            </ol>
            <button onClick={toggleInstructions} style={commonButtonStyle}>Got it!</button>
          </div>
        </>
      )}
      <div style={styles.mapContainer}>
        <div style={styles.mapSection}>
          {pointsReady && (
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '50vh', marginBottom: '10px' }}
              center={pointA || undefined}
              zoom={15}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
              }}
              onLoad={(map) => {
                mapRefA.current = map;
                if (pointA && pointB) {
                  const bounds = new window.google.maps.LatLngBounds();
                  bounds.extend(pointA);
                  bounds.extend(pointB);
                  map.fitBounds(bounds);
                  map.setZoom(0); // Set the zoom level to 0 (maximum zoom out)
                  setMapABounds(map.getBounds() || null);
                }
              }}
              onBoundsChanged={() => {
                const map = mapRefA.current;
                if (map) {
                  const newBounds = map.getBounds();
                  setMapABounds(newBounds || null);
                }
              }}
            >
            {pointAStores.map((store, index) => (
              <Marker
                key={store.place_id || store.name}
                position={{
                  lat: store.geometry?.location?.lat() || 0,
                  lng: store.geometry?.location?.lng() || 0,
                }}
                title={store.name}
                onClick={() => {
                  addToPath(store);
                }}
                icon={{
                  url: selectedStores.has(store.place_id || store.name || '') 
                    ? 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                    : 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
              />
            ))}
            
              {pointA && (
                <MarkerF 
                  position={pointA}
                  icon={{
                    url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                  title="Starting Point"
                />
              )}
    
              {pointB && (
                <MarkerF 
                  position={pointB}
                  icon={{
                    url: selectedStores.has('pointB') 
                      ? 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                      : 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                  title="Ending Point"
                  onClick={() => {
                    const pointBStore: Store = {
                      name: placesData.pointB,
                      geometry: { location: new google.maps.LatLng(pointB.lat, pointB.lng) },
                      chain: 'PointB',
                      city: 'Unknown' // We'll update this later if needed
                    };
                    addToPath(pointBStore);
                    setSelectedStores(prev => new Set(prev).add('pointB'));
                    setGameCompleted(true);
                  }}
                />
              )}
            </GoogleMap>
          )}
          <form onSubmit={(e) => {
            e.preventDefault();
            const input = e.currentTarget.elements.namedItem('pointA') as HTMLInputElement;
            handleSearch(mapABounds, setPointAStores, input.value);
            input.blur(); // Hide the OS keyboard
          }}>
            <input 
              type="text" 
              name="pointA" 
              placeholder="Search for stores or restaurants in Point A" 
              style={{
                padding: '9px',
                fontSize: '16px',
                height: '38px',
                boxSizing: 'border-box',
                verticalAlign: 'top',
                marginRight: '20px',
                width: '60%'  // 60% of the map width
              }}
            />
            <button type="submit" style={commonButtonStyle}>Search</button>
          </form>
          <div style={{ marginTop: '20px', color: 'white' }}>
            {selectedPath.length === 0 
              ? `You're at ${pointAName}` 
              : selectedPath.length > 1 && lastSelectedPlace === placesData.pointB
                ? remainingTime > 0
                  ? `Congrats, you reached the rogue runner in time and neutralized their actions!`
                  : `You didn't reach the rogue runner in time.`
                : selectedPath.length === 1
                  ? `You're at ${lastSelectedPlace}`
                  : selectedPath.length > 1 && isSameChain(selectedPath[selectedPath.length - 2], selectedPath[selectedPath.length - 1])
                    ? `You teleported to ${lastSelectedPlace}`
                    : `You ran to ${lastSelectedPlace}`
            }
            {!gameCompleted && (
              <>
                <button onClick={() => {
                  if (mapRefA.current) {
                    const lastLocation = selectedPath.length > 0 
                      ? selectedPath[selectedPath.length - 1].geometry?.location 
                      : pointA ? new google.maps.LatLng(pointA.lat, pointA.lng) : null;
                    if (lastLocation) {
                      mapRefA.current.panTo(lastLocation);
                      mapRefA.current.setZoom(15);
                    }
                  }
                }} style={{ background: 'none', border: 'none', padding: 0, font: 'inherit', cursor: 'pointer' }}>↗️</button>
                , the rogue runner is at {placesData.pointB} 
                <button onClick={() => {
                  if (mapRefA.current && pointB) {
                    mapRefA.current.panTo(pointB);
                    mapRefA.current.setZoom(15);
                  }
                }} style={{ background: 'none', border: 'none', padding: 0, font: 'inherit', cursor: 'pointer' }}>↗️</button>
              </>
            )}
          </div>
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <h3 style={{ color: 'white', fontSize: '1em', marginRight: '10px' }}>Remaining time:</h3>
              <div style={{ fontSize: '2.1em', fontWeight: 'bold', color: timeColor }}>
                <span style={{ fontFamily: "'Tiny5', sans-serif" }}>{tMinusDisplay.split(':')[0]}</span>
                :
                <span style={{ fontFamily: "'Tiny5', sans-serif" }}>{tMinusDisplay.split(':')[1]}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
